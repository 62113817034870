<template>
  <transition name="dialog-fade">
    <div class="dialog-backdrop">
      <div class="dialog"
        role="dialog"
        aria-labelledby="dialogTitle"
        aria-describedby="dialogDescription"
      >
        <div class="dialog-container" :class="`dialog-${size}`">
            <header
            class="dialog-header"
            id="dialogTitle"
            >
            <slot name="header">
            </slot>
            <button
                type="button"
                class="btn-close text-gray-500"
                @click="close"
                aria-label="Close dialog"
            >
                x
            </button>
            </header>

            <section
            class="dialog-body"
            id="dialogDescription"
            >
            <slot name="body">
                This is the default body!
            </slot>
            </section>

            <footer class="dialog-footer">
            <slot name="footer">
            </slot>
            <!-- <button
                type="button"
                class="btn-green"
                @click="close"
                aria-label="Close dialog"
            >
                Close me!
            </button> -->
            </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    size: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
  .dialog-backdrop {
    position: absolute;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 130%;
    min-height: 110%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
        transform: translateX(-290px);
  }

  .dialog {
    display: table-cell;
    vertical-align: middle;
  }

  .dialog-container {
    margin: 0px auto;
    padding: 10px 25px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.dialog-sm{
 width: 550px;
}
.dialog-md{
 width: 700px;
}
.dialog-lg{
 width: 800px;
}

.dialog-header,
.dialog-footer {
  padding: 15px;
  display: flex;
}

  .dialog-header {
    position: relative;
    justify-content: space-between;
  }

  .dialog-footer {
    /* border-top: 1px solid #eeeeee; */
    flex-direction: column;
  }

  .dialog-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;

    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }

  .dialog-fade-enter,
  .dialog-fade-leave-to {
    opacity: 0;
  }

  .dialog-fade-enter-active,
  .dialog-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
